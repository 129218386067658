import React, { useState, useEffect } from 'react';

import MagicLinkToast from '@amazd/common/components/MagicLinkToast';
import useMagicLinkClientToken from '@amazd/common/hooks/useQueryTokenClientIdAndRedirect';
import useRequestMagicLink from '@amazd/common/hooks/useRequestMagicLink';
import { useTranslation } from '@amazd/common/utils/i18n';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Grid, Hidden, TextField, Typography } from '@mui/material';
import WelcomeMessage from 'components/WelcomeMessage';
import useLogin from 'hooks/useLogin';
import AuthLayout from 'layouts/AuthLayout';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { useFirebaseCloudMessaging } from 'utils/firebase';
import { v4 } from 'uuid';

import useSchema from './schema';
import useStyles from './styles';

type FormData = {
  email: string;
  password: string;
};

const LoginView: React.FC = () => {
  const classes = useStyles();
  const schema = useSchema();
  const firebaseCloudMessaging = useFirebaseCloudMessaging();

  // this will check if it's logged in then it will redirect to
  // dashboard page
  useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const currentUrl = new URL(window.location.href);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { isMagicLinkSent, loading, handleRequestMagicLink } = useRequestMagicLink();

  const tokenClientId = localStorage.getItem('tokenClientId');

  const { loginWithClientToken } = useMagicLinkClientToken();

  const { t } = useTranslation();

  const setupNotifications = async () => {
    await firebaseCloudMessaging.requestNotificationPermission();
  };

  useEffect(() => {
    setupNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: FormData) => {
    const tokenClientId = v4();
    localStorage.setItem('tokenClientId', tokenClientId);
    //Company handle amazd, because we only use that field for shoppers.
    try {
      await handleRequestMagicLink(data.email, currentUrl.toString(), 'amazd', tokenClientId);
      if (errorMessage) setErrorMessage(null);
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  };

  useEffect(() => {
    if (tokenClientId) {
      loginWithClientToken(tokenClientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMagicLinkSent) {
      const tokenClientId = localStorage.getItem('tokenClientId');
      if (tokenClientId) {
        const time = setInterval(
          () =>
            loginWithClientToken(tokenClientId).catch((e) => {
              console.error(e);
              clearInterval(time);
            }),
          3000,
        );
        return () => clearInterval(time);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMagicLinkSent]);

  return (
    <AuthLayout title={'Login'}>
      <Grid container className={classes.container} spacing={0}>
        <Hidden xsDown>
          <Grid className={classes.welcomeMessage} item sm={6}>
            <WelcomeMessage />
          </Grid>
        </Hidden>

        <Grid className={classes.loginFormContainer} item sm={6} container justifyContent="center" alignItems="center">
          <div className={classes.formWrapper}>
            <Typography align="left" className={classes.title} variant="h5">
              {t('login-welcome')}
            </Typography>
            {errorMessage && (
              <Typography
                align="left"
                color="secondary"
                component="p"
                variant="caption"
                style={{ marginTop: 20 }}
                data-testid="validation-error"
              >
                {t(errorMessage)}
              </Typography>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                disabled={isMagicLinkSent}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                className={classes.textField}
                label={t('login-input-emailPhone')}
                variant="outlined"
                inputProps={{
                  'data-testid': 'email-input',
                }}
                {...register('email')}
              />
              {isMagicLinkSent ? (
                <MagicLinkToast style={{ marginTop: 16 }} message={t('magic-link-request-success')} />
              ) : (
                <Button
                  type="submit"
                  disabled={loading}
                  className={classes.registerButton}
                  variant="contained"
                  color="primary"
                  data-testid="submit-button"
                >
                  {t('login-button-email')}
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              )}
            </form>
            <div className={classes.subNotesContainer}>
              <Typography align="center" variant="subtitle1">
                {t('need-an-account-question-text')}&nbsp;
                <Link href={`https://www.amazd.co/free-trial`} data-testid="link-to-request-your-free-trial">
                  {t('link-to-request-your-free-trial')}
                </Link>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default LoginView;
