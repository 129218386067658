import { makeStyles } from '@mui/styles';
import { buttonProgress } from 'styles/theme';

const formElement = {
  width: '100%',
  marginTop: 20,
};

export default makeStyles(() => ({
  registerButton: formElement,
  container: {
    textAlign: 'center',
    flexDirection: 'row-reverse',
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    overflowY: 'auto',
  },
  welcomeMessage: {
    width: '100%',
  },
  loginFormContainer: {
    background: 'white',
  },
  formWrapper: {
    width: 375,
    padding: 20,
  },
  title: {
    marginBottom: 13,
    fontWeight: 500,
  },
  paragraph: {
    textAlign: 'left',
  },
  textField: {
    ...formElement,
  },
  subNotesContainer: {
    marginTop: 40,
  },
  buttonProgress,
}));
