import { makeStyles } from '@mui/styles';

import { colors } from '../../static';

export default makeStyles(() => ({
  container: {
    marginTop: 8,
    padding: 12,
    borderRadius: 8,
    alignItems: 'center',
    display: 'flex',
    background: colors.blue10,
  },
  iconContainer: {
    flexShrink: 0,
    width: 40,
    height: 40,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    borderRadius: '100%',
  },
  infoText: {
    textAlign: 'left',
    lineHeight: '20px',
    fontSize: 14,
    letterSpacing: '-0.02em',
  },
}));
