import { makeStyles } from '@mui/styles';
import { CustomTheme } from 'styles/theme';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 25,
    ...theme.widgetBackgroundCover,
    background: 'black',
  },
  welcomeMessage: {
    marginTop: 40,
    maxWidth: 385,
    height: 66,
    lineHeight: '22px',
    color: 'white',
  },
  welcomeImage: {
    maxWidth: 299,
    height: 316,
    marginTop: 108,
  },
  logo: {
    width: 175,
    height: 57,
  },
}));
