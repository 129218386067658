import { useState } from 'react';

import { gql } from '@apollo/client';

import { getClient } from '../apollo';

export const requestMagicLinkMutation = gql`
  mutation requestMagicLink($args: RequestMagicLinkDto!) {
    requestMagicLink(args: $args)
  }
`;

const useRequestMagicLink = () => {
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRequestMagicLink = async (
    email: string,
    sourceUrl: string,
    companyHandle?: string,
    tokenClientId?: string,
  ) => {
    setLoading(true);
    try {
      const res = await getClient().mutate({
        mutation: requestMagicLinkMutation,
        variables: {
          args: {
            email,
            sourceUrl,
            companyHandle,
            tokenClientId,
          },
        },
      });

      if (res.data.requestMagicLink) {
        setIsMagicLinkSent(true);
      }
    } catch (e: any) {
      throw new Error(e.message || "Couldn't send magic link");
    } finally {
      setLoading(false);
    }
  };

  return { isMagicLinkSent, loading, handleRequestMagicLink };
};

export default useRequestMagicLink;
