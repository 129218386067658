import React from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { Grid, Hidden, Typography } from '@mui/material';

import { useStyles } from './styles';

export default function WelcomeMessage(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.root}>
      <img className={classes.logo} src="/amazd-logo-negative.png" />
      <Typography className={classes.welcomeMessage}>{t('login-welcome-long-message')}</Typography>
      <Hidden xsDown>
        <img className={classes.welcomeImage} src="static/amazd-welcome-page-bubbles.png" />
      </Hidden>
    </Grid>
  );
}
