import { useEffect, useState } from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import * as yup from 'yup';

const useSchema = (): any => {
  const [schema, setSchema] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    // use t for error translation here
    setSchema(
      yup.object().shape({
        email: yup.string().required().email(),
      }),
    );
  }, [t]);

  return schema;
};

export default useSchema;
