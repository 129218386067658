import React from 'react';

import EmailSentIcon from '@amazd/common/icons/EmailSentIcon';
import { Grid, Typography } from '@mui/material';

import { colors } from '../../static';
import useStyles from './styles';

interface Props {
  message: string;
  style?: React.CSSProperties;
}

const MagicLinkToast = ({ message, style }: Props) => {
  const classes = useStyles();
  return (
    <Grid style={{ ...style }} data-testid="magic-link-toast" className={classes.container}>
      <Grid className={classes.iconContainer}>
        <EmailSentIcon color={colors.blue100} style={{ fontSize: 20, color: 'white' }} />
      </Grid>
      <Typography className={classes.infoText} align="left" lineHeight="20px" letterSpacing="-0.02em" fontSize="14px">
        {message}
      </Typography>
    </Grid>
  );
};

export default MagicLinkToast;
