import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { useHead } from 'contexts/headContext';

import { AuthLayoutInterface } from './types';

const AuthLayout: React.FC<AuthLayoutInterface> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, title } = props;
  const head = useHead();

  useEffect(() => {
    head.changeTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <>
      <Grid style={{ height: '100%' }} container direction="row" justifyContent="center" alignItems="center">
        {children || <></>}
      </Grid>
    </>
  );
};

export default AuthLayout;
