import { useCallback, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { onLoginSuccess, onGetMyUserSuccess } from '@amazd/common/utils/auth';
import { gql } from '@apollo/client';

const removeTokenClientId = () => {
  localStorage.removeItem('tokenClientId');
};

const newQuery = gql`
  query authByTokenClientId($tokenClientId: String!) {
    authByTokenClientId(tokenClientId: $tokenClientId) {
      accessToken
      refreshToken
      isValidated
      accessToken
      refreshToken
      user {
        id
        email
        firstName
        lastName
        timezone
        role
        phone
      }
      userTypes {
        id
        avatar
        isExpert
        handle
        locale
        company {
          id
          handle
        }
      }
    }
  }
`;

const useMagicLinkClientToken = () => {
  const [loginFail, setLoginFail] = useState(false);

  const loginWithClientToken = useCallback(async (tokenClientId: string) => {
    try {
      const { data } = await getClient().query({
        variables: {
          tokenClientId,
        },
        query: newQuery,
        fetchPolicy: 'no-cache',
      });

      if (data) {
        if (data.authByTokenClientId.user && data.authByTokenClientId.userTypes) {
          removeTokenClientId();
          onGetMyUserSuccess({
            ...data.authByTokenClientId.user,
            userTypes: [...data.authByTokenClientId.userTypes],
          });
          onLoginSuccess(data.authByTokenClientId);
        } else if (data.authByTokenClientId.isValidated === false) {
          setLoginFail(true);
        }
      }
    } catch (e: any) {
      setLoginFail(true);
      removeTokenClientId();
      throw e;
    }
  }, []);

  return { loginWithClientToken, loginFail };
};

export default useMagicLinkClientToken;
