import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const EmailSentIcon: React.FC<any> = (props: any): React.ReactElement => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.00003 10C0.997133 8.81767 1.22754 7.64638 1.67806 6.55323C2.12858 5.46009 2.79035 4.46657 3.62547 3.6296C4.46058 2.79263 5.45262 2.12865 6.54476 1.6757C7.63691 1.22276 8.80768 0.989742 9.99003 0.990009C14.96 0.980009 18.99 5.01001 18.99 9.98001C18.99 12.367 18.0418 14.6561 16.354 16.344C14.6662 18.0318 12.377 18.98 9.99003 18.98M2.00003 18L5.00003 15M6.00003 19L7.00003 18M1.00003 14L2.00003 13M7.84503 13.491L7.02503 10.811L4.69503 10.081L4.68503 10.071C4.64757 10.0599 4.61268 10.0415 4.58234 10.0169C4.55201 9.99225 4.52683 9.96188 4.50825 9.92751C4.48967 9.89314 4.47806 9.85544 4.47407 9.81657C4.47009 9.77771 4.47381 9.73843 4.48503 9.70101C4.50503 9.61101 4.57503 9.54101 4.65503 9.51101L13.505 6.09101V6.08101C13.725 5.99101 13.985 6.10101 14.075 6.33101C14.095 6.40101 14.105 6.49101 14.095 6.57101L12.608 13.571V13.561C12.538 13.871 12.228 14.071 11.918 14.001C11.8345 13.9808 11.7562 13.9434 11.688 13.891L9.57603 12.331L8.32603 13.541V13.531C8.19603 13.651 7.98603 13.651 7.86603 13.521C7.82686 13.4867 7.79897 13.4414 7.78603 13.391L7.84503 13.491Z"
      stroke={props?.color || 'inherit'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default EmailSentIcon;
